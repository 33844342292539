import axios from "axios";
const {
    ADD_BILLSPAY,
    UPDATE_BILLSPAY,
    GET_ALL_BILLSPAY,
    DESTROY_BILLSPAY,
    DOWNLOAD_BILLSPAY,
    FIND_BILLSPAY,
    AUTO_COMPLETE,
    GET_BILLS_PAY_BY_STATUS,
    BILLS_PAY_REVERSAL,
    AUTO_COMPLETE_BILLS_TO_PAY_BY_STATUS,
    GET_ALL_BILLS_PAY_BY_DATE,
    DOWNLOAD_BILLSPAY_MULTIPLE,
    GET_ALL_BILLSPAY_BY_STATUS,
    AUTO_COMPLETE_BY_ENTERPRISE_BILLSPAY,
    DATE_FILTER_BILLSPAY
} = require("./actions/bills_pay");

const {
    SPLICE_ERRO,

} = require('./actions/alert_api')

const moduleBills_pay = {
    state: () => ({
        BillPays: Object(),
        autoCompleteBillsToPay: Object(),
        titleReversal: Object(),
        BillPaysByStatus: Object(),
        downloadCompleted: Object(),
    }),

    mutations: {
        GET_BILLS_PAY_BY_STATUS: (state, payload) => {
            state.BillPaysByStatus = { ...payload };
        },
        AUTO_COMPLETE_BILLS_TO_PAY_BY_STATUS: (state, payload) => {
            state.BillPaysByStatus = { ...payload };
        },
        GET_ALL_BILLS_PAY_BY_DATE: (state, payload) => {
            state.BillPaysByStatus = { ...payload };
        },
        GET_REPORT_BILLSPAY: (state, payload) => {
            state.BillPaysByStatus = { ...payload };
        },
        GET_ALL_BILLSPAY: (state, payload) => {

            if (payload.length > 0) {
                payload.data.forEach(e => {
                    if (e.provider.type_people == "LegalPeople") {
                        e.provider.people.legalpeople.name = e.provider.people.legalpeople.fantasyName;
                    }
                });
            }
            state.BillPays = payload;

        },
        GET_ALL_BILLSPAY_BY_STATUS: (state, payload) => {
            if (payload.length > 0) {
                payload.data.forEach(e => {
                    if (e.provider.type_people == "LegalPeople") {
                        e.provider.people.legalpeople.name = e.provider.people.legalpeople.fantasyName;
                    }
                });
            }
            state.BillPays = payload;

        },

        DATE_FILTER_BILLSPAY: (state, payload) => {
            if (payload.length > 0) {
                payload.data.forEach(e => {
                    if (e.provider.type_people == "LegalPeople") {
                        e.provider.people.legalpeople.name = e.provider.people.legalpeople.fantasyName;
                    }
                });
            }
            state.BillPays = payload;
        },

        AUTO_COMPLETE_BY_ENTERPRISE_BILLSPAY: (state, payload) => {
            if (payload.data) {
                payload.data.forEach(e => {
                    if (e.provider.type_people == "LegalPeople") {
                        e.provider.people.legalpeople.name = e.provider.people.legalpeople.fantasyName;
                    }
                });
            } else{
                payload = []
            }
            state.BillPays = payload;
        },

        ADD_BILLSPAY: (state, newBillPays) => {
            state.BillPays.data.push(newBillPays);
        },

        UPDATE_BILLSPAY(state, payload) {
            let auxState = { ...state.BillPays }
            if (payload.provider.type_people == "LegalPeople") {
                payload.provider.people.legalpeople.name = payload.provider.people.legalpeople.fantasyName;
            }

            auxState.data.Update(payload)
            state.BillPays = auxState
        },

        DESTROY_BILLSPAY: (state, id) => {
            let auxState = [...state.BillPays.data];
            let index = auxState.indexOf(
                auxState.find(value => value.id === id),
            );
            if (index >= 0) {
                auxState.splice(index, 1);
            }
            state.BillPays = [...auxState];
        },

        DOWNLOAD_BILLSPAY: (state, payload) => {

            state.downloadCompleted = payload
            let auxData = state.BillPays
            auxData.data.forEach((e) => {
                if (e.id === payload.billstoPay_id) {
                    e.title_balance = payload.value_low - e.title_balance
                    if (e.title_balance === 0) { e.status = 'Baixado' }
                }
            })
            state.BillPays = auxData

        },

        DOWNLOAD_BILLSPAY_MULTIPLE: (state, payload) => {

            state.downloadCompleted = payload
            let auxData = state.BillPays
            auxData.data.forEach((e) => {
                if (e.id === payload.billstoPay_id) {
                    e.title_balance = payload.value_low - e.title_balance
                    if (e.title_balance === 0) { e.status = 'Baixado' }
                }
            })
            state.BillPays = auxData

        },

        FIND_BILLSPAY: (state, payload) => {
            state.BillPays = payload
        },

        BILLS_PAY_REVERSAL: (state, payload) => {
            state.titleReversal = payload
        },

        AUTO_COMPLETE: (state, payload) => {
            if (payload.length > 0) {
                payload.data.forEach(e => {
                    if (e.provider.type_people == "LegalPeople") {
                        e.provider.people.legalpeople.name = e.provider.people.legalpeople.fantasyName;
                    }
                });
            }
            state.BillPays = payload;
        }
    },

    actions: {
        async FIND_BILLSPAY({ commit }, payload) {
            await axios.post('billstopay/find', {
                    date_initial: payload.date_initial,
                    date_final: payload.date_final,
                    enterprise_id: payload.enterprise_id,
                    page: payload.page,
                    limit: payload.limit,
                    paginate: payload.paginate,
                
            }).then((response) => {
                commit(FIND_BILLSPAY, response.data[0])
                commit(SPLICE_ERRO, {
                    alert: response.data.menssage[0],
                    type: 'S'
                })
            })
        },

        async DOWNLOAD_BILLSPAY({ commit }, payload) {
    
            await axios.post('billstopay/downloadTitle', payload)
                .then((response) => {
                    commit(DOWNLOAD_BILLSPAY, response.data[0])
                    commit(SPLICE_ERRO, {
                        alert: "Título Baixado com sucesso!",
                        type: 'S'
                    })
                })
        },

        DOWNLOAD_BILLSPAY_MULTIPLE(context, payload) {
            try {
                axios.post('/billstopay/multipleTitleDownload', payload).then(response => {
                    context.commit(SPLICE_ERRO, {
                        alert: "Título Baixado com sucesso!",
                        type: 'S'
                    })
                    context.commit(DOWNLOAD_BILLSPAY_MULTIPLE, response.data[0])
                })
            } catch (error) {
                context.commit(SPLICE_ERRO, {
                    alert: "error",
                    type: 'S'
                })
            }
        },
        
        async BILLS_PAY_REVERSAL({commit}, payload) {
            await axios.post('/billstopay/reversalBillsToPay', payload)
            .then((response) => {
                commit(BILLS_PAY_REVERSAL, response.data[0])
                commit(SPLICE_ERRO, {
                    alert: "Título Estornado com sucesso!",
                    type: 'S'
                })
            })
        },

        GET_ALL_BILLSPAY({
            commit
        }, payload) {
            axios.post("billstopay/index", { page: payload.page, limit: payload.limit, paginate: payload.paginate }).then((response) => {
                commit(GET_ALL_BILLSPAY, response.data[0]);
            });
        },

        async GET_REPORT_BILLSPAY({ commit }, payload) {      
             try {        
                const response = await axios.post("catalog/billstopay", payload)        
                commit(SPLICE_ERRO, {
                    alert: 
                    response.data.msg, type: 'S'
                })        
                return response      
            }
            catch (error) {
                commit(SPLICE_ERRO, { alert: error.response.data.error,  type: 'E' })     
            } 
        },
        
        GET_ALL_BILLSPAY_BY_STATUS({
            commit
        }, payload) {
            axios.post("billstopay/getAllByEnterprise", payload).then((response) => {
                commit(GET_ALL_BILLSPAY_BY_STATUS, response.data);
            });
        },

        DATE_FILTER_BILLSPAY({
            commit
        }, payload) {
            axios.post("billstopay/getAllByIssuanceDate", payload).then((response) => {
                commit(DATE_FILTER_BILLSPAY, response.data);
            });
        },

        async ADD_BILLSPAY({
            commit
        }, payload) {
            return new Promise((resolve, reject) => {

                axios.post("billstopay/store", payload).then((response) => {
                    resolve()
                    commit(ADD_BILLSPAY, response.data[0]);
                    commit(SPLICE_ERRO, {
                        alert: 'Cadastrado com Sucesso!',
                        type: 'S'
                    })
                }).catch(() => {
                    reject()
                })

            })
        },
        async UPDATE_BILLSPAY({
            commit
        }, payload) {

            return new Promise((resolve, reject) => {

                axios.post("billstopay/update", payload).then((response) => {
                    resolve()
                    commit(UPDATE_BILLSPAY, response.data[0]);
                    commit(SPLICE_ERRO, {
                        alert: 'Cadastrado com Sucesso!',
                        type: 'S'
                    })
                }).catch(() => {
                    reject()
                })

            })
        },

        AUTO_COMPLETE: function (context, payload) {
            try {
                axios.post('/billstopay/autocomplete', payload).then(response => {
                    context.commit(AUTO_COMPLETE,response.data[0])
                })
            } catch (error) {
                throw new Error(error)
            }
        },

        AUTO_COMPLETE_BY_ENTERPRISE_BILLSPAY: function (context, payload) {
            try {
                axios.post('/billstopay/autocompleteByEnterprise', payload).then(response => {
                    context.commit(AUTO_COMPLETE_BY_ENTERPRISE_BILLSPAY,response.data)
                })
            } catch (error) {
                throw new Error(error)
            }
        },
        
        DESTROY_BILLSPAY({
            commit
        }, payload) {
            axios.post("billstopay/delete", {
                id: payload.id
            }).then((response) => {
                commit(DESTROY_BILLSPAY, payload);
                commit(SPLICE_ERRO, {
                    alert: response.data.sucess.message[0],
                    type: 'S'
                })
            }).catch(r => {
                let erros = r.response.data.errors
                for (let erro in erros) {
                    erros[erro].forEach(err => commit(SPLICE_ERRO, {
                        alert: err,
                        type: 'E'
                    }))
                }
            })
        },
        GET_BILLS_PAY_BY_STATUS({
            commit
        }, payload) {
            axios.post("/billstopay/indexByStatus", {status: payload.status, page: payload.page, limit: payload.limit, paginate: payload.paginate }).then((response) => {
                commit(GET_BILLS_PAY_BY_STATUS, response.data);
            });
        },

        AUTO_COMPLETE_BILLS_TO_PAY_BY_STATUS({
            commit
        }, payload) {
            axios.post("/billstopay/autocompleteByStatus", payload).then((response) => {
                commit(AUTO_COMPLETE_BILLS_TO_PAY_BY_STATUS, response.data);
            });
        },
        GET_ALL_BILLS_PAY_BY_DATE({
            commit
        }, payload) {
            axios.post("/billstopay/getAllByDueDate", payload).then((response) => {
                commit(GET_ALL_BILLS_PAY_BY_DATE, response.data);
            });
        },
    },

    getters: {
        getBillPays: (state) => ({ ...state.BillPays }),
        getBillPaysByStatus: (state) => ({ ...state.BillPaysByStatus }),
        getAutoCompleteBillsToPay: (state) => state.autoCompleteBillsToPay,
        getDownloadCompleted: (state) => state.downloadCompleted,
    },
};

export default moduleBills_pay;