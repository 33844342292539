<template>
  <v-form ref="form" v-model="valid">
    <v-row class="ml-2">
      <v-col cols="2">
        <v-text-field v-model="component.filter.date_initial" label="Data inicial do vencimento" type="date"
          :rules="[(v) => !!v || 'Campo obrigatório para Buscar']" outlined></v-text-field>
      </v-col>

      <v-col cols="2">
        <v-text-field v-model="component.filter.date_final" label="Data final do vencimento"
          :rules="[(v) => !!v || 'Campo obrigatório para Buscar']" type="date" outlined>
        </v-text-field>
      </v-col>
      
      <v-col cols="2">
        <v-select v-model="component.filter.status" label="Status" item-text="description" item-value="value"
          :items="statusItems" outlined>
        </v-select>
      </v-col>

      <div style="margin-top: 12px;">
        <v-btn class="mt-2" :disabled="!valid" color="success" rounded
          @click="resetFilter()"><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <v-col><v-btn class="mt-2" :disabled="!valid" color="success" rounded
          @click="component.findByFilter()"><v-icon>mdi-magnify</v-icon></v-btn></v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  props: {
    component: Object(),
  },

  data: () => ({
    valid: Boolean(),
    data: String(),
    statusItems: [
      { value: null, description: 'Todos' },
      { value: 'Aberto', description: 'Aberto' },
      { value: 'Baixado', description: 'Baixado' },
    ],
  }),

  created() {
    this.setDates()
  },

  methods: {
    resetFilter() {
      this.component.filter.date_initial = ""
      this.component.filter.date_final = ""
      this.component.filter.status = null
      this.$refs.form.reset()
      this.component.clearFilter()
    },

    setDates() {
      const today = new Date();
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      
      this.component.filter.date_initial = firstDayOfMonth.toISOString().split('T')[0];
      this.component.filter.date_final = today.toISOString().split('T')[0];
    }
  }
};
</script>

<style></style>