<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="results"
      no-data-text="Nenhum resultado encontrado"
      no-results-text="Nenhum resultado encontrado"
      hide-default-footer
      @click:row="handleClick"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-if="component.permission.Editar"
              v-on="on"
              :disabled="(item.code == 1102 || item.code == 5102)"
              @click="
                () => {
                  change_addDialog(item);
                }
              "
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar esse Item</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="red darken-4"
              class="mr-2"
              v-if="component.permission.Excluir"
              v-bind="attrs"
              v-on="on"
              :disabled="(item.code == 1102 || item.code == 5102)"
              @click="
                () => {
                  deleteItem(item);
                }
              "
              >mdi-delete</v-icon
            >
          </template>
          <span>Deletar esse Item</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination
      class="mb-3"
      v-model="current"
      color="success"
      :length="total"
      :total-visible="10"
    ></v-pagination>
    <DestryDialog :component="this" :destroy_object="destroy_object" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DestryDialog from "./../DestroyDialog/";
import {
  AUX_TO_UPDATE_TAX_OPERATION_TYPE,
  GET_ALL_TAX_OPERATION_TYPE,
} from "../../../store/actions/tax_operation_types";
export default {
  props: {
    component: Object,
    getters: Object,
    destroy_object: Function,
    search: String(),
    headers: Array,
    //change_addDialog: Function,
  },

  components: { DestryDialog },

  data: () => ({
    destroyDialog: false,
    currentItem: {},
    results: Array(),
    total: Number(),
    current: Number(),
    itemSelectedOnClick: Object(),
  }),
  computed: {
    ...mapGetters(["getNewTaxOperationToAdd", "getTaxOperationType"]),
  },
  methods: {
    async GetAll(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store
        .dispatch(GET_ALL_TAX_OPERATION_TYPE, value)
    
    },
    change_addDialog(item) {
      this.$store.dispatch("setShowDialogRegisterTaxOperationType", true);
      this.$store.dispatch(AUX_TO_UPDATE_TAX_OPERATION_TYPE, undefined);
      this.$store.dispatch(AUX_TO_UPDATE_TAX_OPERATION_TYPE, item);
    },
    deleteItem(item) {
      this.destroyDialog = true;
      this.currentItem = item;
    },

    handleClick(i) {
      this.itemSelectedOnClick = i;
    },
  },

  created() {
    // this.component.GetAll();
    this.GetAll();
  },

  watch: {
    getNewTaxOperationToAdd: function (val) {
      this.results.push(val);
    },

    getTaxOperationType: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },

    
  },
};
</script>
