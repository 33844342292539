<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" ref="CardTitle" />
      </v-card-text>
      <v-card-text>
        <DateFilter :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field 
        v-model="searchInput" 
        label="Pesquisar"
        outlined
        append-icon="mdi-magnify"
        @keyup.enter="setAutocomplete()"
        >
        </v-text-field>S
      </v-card-text>

      <Table
        v-if="permission.Visualizar"
        :component="this"
        :getters="getBillReceive"
        :destroy_object="destroy_object"
        :search="search"
        :headers="headers"
        :change_addDialog="change_addDialog"
        :change_downloadDialog="change_downloadDialog"
      />
      <ModalBillsReceive v-if="addDialog" :component="this" />
      <DownloadBillsReceive
        v-if="downloadialog"
        :component="this"
        download="DOWNLOAD_BILLSRECEIVE"
      />
    </v-card>
    <AlertApi />
    <loadScreen />
  </Container>
</template>


<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/bills_receive/CardTitle/";
import Table from "../components/bills_receive/Table/";
import ModalBillsReceive from "../components/bills_receive/AddDialog/";
import DownloadBillsReceive from "../components/bills_receive/DownloadBills/";
import loadScreen from "../components/load/";
import AlertApi from "../components/alert_api/alert_api";
import DateFilter from "../components/bills_receive/DateFilter/";

import {
  GET_ALL_BILLSRECEIVE_BY_ENTERPRISE,
  DESTROY_BILLSRECEIVE,
  UPDATE_BILLSRECEIVE,
  AUTO_COMPLETE_BY_ENTERPRISE,
  DATE_FILTER_BILLSRECEIVE
} from "../store/actions/bills_receive";

import { GET_ALL_ACCOUNT_PLAN } from "../store/actions/account_plan";
import { mapGetters } from "vuex";

export default {
  components: {
    Container,
    CardTitle,
    Table,
    ModalBillsReceive,
    loadScreen,
    DownloadBillsReceive,
    AlertApi,
    DateFilter
  },
  data: () => ({
    enable_disable_save_addDialog:false,
    searchInput: String(),
    search: "",
    getter: "getBillReceive",
    addDialog: false,
    downloadialog: false,
    enterprise_id: Number(),
    objectToUpdate: {},
    itemToDownload: {},
    permission: Object(),
    title: "Contas a Receber",
    headers: [
      
      { text: "ID", value: "id",align: 'center' },
      { text: "Status", value: "status",align: 'center' },
      { text: "Tipo Pessoa", value: "client.type_people",align: 'center' },
      { text: "Codigo do Cliente", value: "client.id",align: 'center' },
      { text: "Nome do Cliente", value: "client.people.name",align: 'center' }, 
      { text: "Tipo do titulo", value: "type_title",align: 'center' },
      { text: "Data Emissão", value: "issuance_date",align: 'center' },
      { text: "Data Vencimento", value: "issuance_due",align: 'center' },
      { text: "Numero do titulo", value: "number_title",align: 'center' },
      { text: "Valor do titulo", value: "value_title",align: 'center' },
      { text: "Valor liquido", value: "value_net",align: 'center' },
      { text: "Saldo titulo", value: "title_balance",align: 'center' },
      { text: "Ações", value: "actions", align: 'center' },
    ],
    filterByDateFlag: Boolean(),
    filter: {
      status: null,
      date_initial: String(),
      date_final: String(),
    },
  }),
  computed: {
    ...mapGetters(["getBillReceive", "getFinanceiroLinks", "getAccount_Plan"]),
  },
  methods: {
    async destroy_object(item) {
      const bank = {
        id: item.id,
      };
      try {
        await this.$store.dispatch(DESTROY_BILLSRECEIVE, bank);
      } catch (error) {
        alert(error);
      }
    },
    change_addDialog(item) {
      this.addDialog = !this.addDialog;
      this.objectToUpdate = item;
      this.enable_disable_save_addDialog = true
      
    },
    change_downloadDialog(item) {
      this.downloadialog = !this.downloadialog;
      this.itemToDownload = item;
    },
    async update_object(item) {
      const bank = {
        id: item.id,
      };
      try {
        await this.$store.dispatch(UPDATE_BILLSRECEIVE, bank);
      } catch (error) {
        alert(error);
      }
    },
    async GetAll(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
        enterprise_id: this.enterprise_id
      };
      await this.$store.dispatch(GET_ALL_BILLSRECEIVE_BY_ENTERPRISE, value);
    },
    async GetAllNoPage() {
      let value = {
        page: null,
        limit: null,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_ACCOUNT_PLAN, value);
    },
    async filterBillsToReceive(page = 1){
        let payload = {
          search: this.search,
          page: page,
          limit:10,
          paginate:true, 
          enterprise_id: this.enterprise_id
        }
        await this.$store.dispatch(AUTO_COMPLETE_BY_ENTERPRISE, payload)
    },

    setAutocomplete() {
      this.search = this.searchInput
    },
    async findByFilter(page = 1) {
      this.filterByDateFlag = true
      let payload = {
        status: this.filter.status,
        initial_date: this.filter.date_initial,
        final_date: this.filter.date_final,
        page: page,
        limit:10,
        paginate:true, 
        enterprise_id: this.enterprise_id
      }
      await this.$store.dispatch(DATE_FILTER_BILLSRECEIVE, payload)
    },
    clearFilter() {
      this.filterByDateFlag = false
      this.GetAll()
    }
  },
  async created() {
    this.GetAllNoPage();
  },
  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getFinanceiroLinks.getPermissions(name);
  },
  watch: {
    search: function(val) {
      if(val.length >= 2) {
        this.filterBillsToReceive()
      }
    },

    searchInput: function(val) {
      if(val.length == 0) {
        this.search = ""
        this.GetAll()
      }
    },

    enterprise_id: function() {
      this.GetAll()
    }
  }
};
</script>


<style scoped>
</style>